//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
export default {
  name: "KTTopbar",
  components: {
    KTQuickUser: KTQuickUser,
    KTDropdownNotification: KTDropdownNotification
  },
  methods: {
    reloadPage: function reloadPage() {
      window.location.reload();
    }
  }
};