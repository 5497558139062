import _objectSpread from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import { ErrorEventBus, SuccessEventBus, ClearEventBus } from "@/core/lib/message.lib";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import JwtService from "@/core/services/jwt.service";
export default {
  name: "Layout",
  components: {
    KTAside: KTAside,
    KTHeader: KTHeader,
    KTHeaderMobile: KTHeaderMobile,
    KTScrollTop: KTScrollTop,
    Loader: Loader
  },
  data: function data() {
    return {
      errors: [],
      success: [],
      timeout: null,
      timeoutLimit: 5000,
      isAuthenticated: false,
      timeInterval: null
    };
  },
  beforeMount: function beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading"); // initialize html element classes

    HtmlClass.init(this.layoutConfig());
  },
  methods: {
    UserAuthentication: function UserAuthentication() {
      var _this = this; // check if current user is authenticated


      try {
        JwtService.isAuthenticated().then(function (response) {
          _this.isAuthenticated = response;

          if (!response) {
            _this.$router.push({
              name: "admin.login"
            });
          }
        }).catch(function () {
          _this.$router.push({
            name: "admin.login"
          });
        });
        JwtService.isEngineer().then(function (response) {
          if (response) {
            _this.$router.push({
              name: "admin.login"
            });
          }
        }).catch(function () {
          _this.$router.push({
            name: "admin.login"
          });
        });
      } catch (error) {
        console.error(error);

        _this.$router.push({
          name: "admin.login"
        });
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.UserAuthentication();

    clearInterval(_this.timeInterval);
    _this.timeInterval = setInterval(function () {
      _this.UserAuthentication();
    }, 5000); // Simulate the delay page loading

    setTimeout(function () {
      // Remove page loader after some time
      _this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timeInterval);
    SuccessEventBus.$off("update:success");
    ErrorEventBus.$off("update:error");
    ClearEventBus.$off("clear:success");
    ClearEventBus.$off("clear:error");
  },
  created: function created() {
    var _this = this;

    SuccessEventBus.$on("update:success", function (success) {
      _this.success.push({
        message: success,
        model: true
      });

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        for (var i = 0; i < _this.success.length; i++) {
          _this.success[i].model = false;
        }
      }, _this.timeoutLimit);
    });
    ErrorEventBus.$on("update:error", function (error) {
      if (_this.lodash.isArray(error)) {
        _this.errors = _this.lodash.concat(_this.errors, error.map(function (row) {
          return {
            message: row,
            model: true
          };
        }));
      } else {
        _this.errors.push({
          message: error,
          model: true
        });
      }

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        for (var i = 0; i < _this.errors.length; i++) {
          _this.errors[i].model = false;
        }
      }, _this.timeoutLimit);
    });
    ClearEventBus.$on("clear:success", function () {
      _this.success = new Array();
    });
    ClearEventBus.$on("clear:error", function () {
      _this.errors = new Array();
    });
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["breadcrumbs", "pageTitle", "layoutConfig"])), {}, {
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled: function loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid: function contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo: function loaderLogo() {
      return process.env.VUE_APP_BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled: function asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay: function subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  })
};