import _objectSpread from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTMenu from "@/view/layout/aside/Menu.vue";
export default {
  name: "KTAside",
  data: function data() {
    return {
      insideTm: 0,
      outsideTm: 0
    };
  },
  components: {
    KTBrand: KTBrand,
    KTMenu: KTMenu
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      // Init Aside
      KTLayoutAside.init(_this.$refs["kt_aside"]); // Init Aside Menu

      KTLayoutAsideMenu.init(_this.$refs["kt_aside_menu"]);
    });
  },
  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter: function mouseEnter() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains("aside-fixed")) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm);
            this.outsideTm = null;
          } // if the left aside menu is minimized


          if (document.body.classList.contains("aside-minimize")) {
            document.body.classList.add("aside-minimize-hover"); // show the left aside menu

            document.body.classList.remove("aside-minimize");
          }
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave: function mouseLeave() {
      if (this.layoutConfig("aside.self.minimize.hoverable")) {
        if (document.body.classList.contains("aside-fixed")) {
          if (this.insideTm) {
            clearTimeout(this.insideTm);
            this.insideTm = null;
          }

          if (document.querySelector(".aside-menu .scroll")) {
            document.querySelector(".aside-menu .scroll").scrollTop = 0;
          } // if the left aside menu is expand


          if (document.body.classList.contains("aside-minimize-hover")) {
            // hide back the left aside menu
            document.body.classList.remove("aside-minimize-hover");
            document.body.classList.add("aside-minimize");
          }
        }
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses"])), {}, {
    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass: function asideMenuClass() {
      var classes = this.getClasses("aside_menu");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    }
  })
};