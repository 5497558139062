import _objectSpread from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import TopSearchbar from "@/view/layout/header/TopSearchbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { SearchEventBus } from "@/core/lib/search.lib";
export default {
  name: "KTHeader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  data: function data() {
    return {
      isSearchActivated: false,
      searchTitle: null
    };
  },
  components: {
    KTTopbar: KTTopbar,
    TopSearchbar: TopSearchbar
  },
  methods: {
    getMenuTitle: function getMenuTitle(title) {
      return title;
    }
  },
  beforeCreate: function beforeCreate() {
    var _this = this; // Init Desktop & Mobile Headers


    KTLayoutHeader.init("kt_header", "kt_header_mobile"); // Init Header Menu

    KTLayoutHeaderMenu.init(_this.$refs["kt_header_menu"], _this.$refs["kt_header_menu_wrapper"]);
    SearchEventBus.$on("search-template", function (argument) {
      _this.isSearchActivated = argument;
    });
    SearchEventBus.$on("search-module-title", function (argument) {
      _this.searchTitle = _this.lodash.capitalize(_this.lodash.lowerCase(argument));
    });
  },
  beforeDestroy: function beforeDestroy() {
    SearchEventBus.$off("search-template");
    SearchEventBus.$off("search-module-title");
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getClasses"])), {}, {
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled: function headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses: function headerClasses() {
      var classes = this.getClasses("header");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses: function headerMenuClasses() {
      var classes = this.getClasses("header_menu");

      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }

      return null;
    }
  })
};